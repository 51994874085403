import { PropertySearchResult, Property } from '../../../services/dtos/property';
import {
  buildURLObject, withQueryString, slugIt, QueryObject, URLObject,
  withCanonicalPageQueryString,
} from './common';
import {
  SERP_URL, LISTING_URL,
  SELL_PROPERTY_URL, SELL_PROPERTY_AS,
  BUY_PROPERTY_URL, BUY_PROPERTY_AS,
  BUILDING_SITES_URL, BUILDING_SITE_AS, BUILDING_SITE_URL, SOLD_PROPERTIES_URL, SOLD_PROPERTIES_AS, SERP_AS,
} from './paths';

export interface BuildSerpURLObject {
  province?: string | null,
  locality?: string | null,
  area?: string | null,
  p?: string | null,
  size_min?: string | null,
  size_max?: string | null,
  price_min?: string | null,
  price_max?: string | null,
  [key: string]: string,
}

export interface BuildListingURLObject {
  id: string,
  locality: string,
  slug: string,
  [key: string]: string,
}

export interface BuildSellPropertyByCityURLObject {
  locality: string,
  [key: string]: string,
}

export function buildListingURLObject(property: PropertySearchResult | Property, query: QueryObject = {}) {
  const id = property.id.toString();
  const { locality } = property;
  const slug = property.relativePath.match(/(\/vendita-case)\/([\w-]+)\/([\w0-9\-_]+)\/([0-9]+)/)[3];

  return buildURLObject(
    LISTING_URL,
    withCanonicalPageQueryString(`/vendita-case/${slugIt(locality)}/${slug}/${id}`, query),
    {
      ...query, id, locality: slugIt(locality), slug,
    },
  );
}

function buildSearchURLObject(path: string, as: string, {
  province,
  locality,
  area,
  ...rest
}: BuildSerpURLObject = {}): URLObject {
  const provinceSlug = province ? slugIt(province) : undefined;
  const localitySlug = locality ? slugIt(locality) : undefined;
  const areaSlug = area ? slugIt(area) : undefined;

  if (provinceSlug) {
    as += `/${provinceSlug}-provincia`;
  } else if (localitySlug) {
    as += `/${localitySlug}${areaSlug ? `/${areaSlug}` : ''}`;
  }

  const query: QueryObject = {
    ...rest,
  };

  if (provinceSlug) {
    query.province = provinceSlug;
  }

  if (!provinceSlug && localitySlug) {
    query.locality = localitySlug;
  }

  if (!provinceSlug && areaSlug) {
    query.area = areaSlug;
  }

  return buildURLObject(path, withCanonicalPageQueryString(as, { ...rest }), query);
}

export function buildSerpURLObject(serpUrlObject: BuildSerpURLObject = {}) {
  return buildSearchURLObject(SERP_URL, SERP_AS, serpUrlObject);
}

export function buildSoldPropertiesURLObject(serpUrlObject: BuildSerpURLObject = {}) {
  return buildSearchURLObject(SOLD_PROPERTIES_URL, SOLD_PROPERTIES_AS, serpUrlObject);
}

export function buildSellPropertyURLObject(query: QueryObject = {}) {
  return buildURLObject(
    SELL_PROPERTY_URL,
    withQueryString(SELL_PROPERTY_AS, query),
    query,
  );
}

export function buildBuyPropertyURLObject(query: QueryObject = {}) {
  return buildURLObject(BUY_PROPERTY_URL, withQueryString(BUY_PROPERTY_AS, query), query);
}

export function buildBuildingSitesURLObject(query: QueryObject = {}) {
  return buildURLObject(
    BUILDING_SITES_URL,
    withQueryString(BUILDING_SITE_AS, query),
    query,
  );
}

export function buildBuildingSiteURLObject({
  id, locality, slug, ...rest
}: QueryObject) {
  return buildURLObject(
    BUILDING_SITE_URL,
    withQueryString(`${BUILDING_SITE_AS}/${slugIt(locality)}/${slug}/${id}`, rest),
    {
      id,
      locality: slugIt(locality),
      slug,
      ...rest,
    },
  );
}
