import { ArticleType } from '../../../domain/articleType';
import {
  buildURLObject, QueryObject, slugIt, withCanonicalPageQueryString, withQueryString,
} from './common';
import {
  EDUCATION_AS,
  EDUCATION_URL,
  EDUCATION_SERP_AS,
  EDUCATION_SERP_URL,
  GUIDES_AS,
  GUIDES_URL,
  GUIDE_AS,
  GUIDE_URL,
  NEWS_AS,
  NEWS_URL,
  NEWS_SERP_AS,
  NEWS_SERP_URL,
} from './paths';

export interface BuildArticleURLObject extends QueryObject {
  id: string,
  slug: string,
  [key: string]: string,
}

const serpPathsMap = {
  [ArticleType.NEWS]: NEWS_SERP_URL,
  [ArticleType.GUIDE]: GUIDES_URL,
  [ArticleType.EDUCATION]: EDUCATION_SERP_URL,
};

const serpAsUrlsMap = {
  [ArticleType.NEWS]: NEWS_SERP_AS,
  [ArticleType.GUIDE]: GUIDES_AS,
  [ArticleType.EDUCATION]: EDUCATION_SERP_AS,
};

const detailPathsMap = {
  [ArticleType.NEWS]: NEWS_URL,
  [ArticleType.GUIDE]: GUIDE_URL,
  [ArticleType.EDUCATION]: EDUCATION_URL,
};

const detailsAsUrlsMap = {
  [ArticleType.NEWS]: NEWS_AS,
  [ArticleType.GUIDE]: GUIDE_AS,
  [ArticleType.EDUCATION]: EDUCATION_AS,
};

// eslint-disable-next-line @typescript-eslint/default-param-last
export function buildArticlesURLObject(query: QueryObject = {}, type: ArticleType) {
  const { category, ...rest } = query;
  const path = category
    ? `${serpAsUrlsMap[type]}/${slugIt(category)}`
    : serpAsUrlsMap[type];

  const queryObject = { ...rest };
  if (category) {
    queryObject.category = slugIt(category);
  }

  return buildURLObject(serpPathsMap[type], withCanonicalPageQueryString(path, rest), queryObject);
}

export function buildArticleURLObject(
  { id, slug, ...rest }: BuildArticleURLObject,
  type: ArticleType,
) {
  return buildURLObject(
    detailPathsMap[type],
    withQueryString(`${detailsAsUrlsMap[type]}/${slug}/${id}`, rest),
    { id, slug, ...rest },
  );
}
